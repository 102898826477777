define('fti-pump-selector/components/default-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    defaultOkText: 'Ok',
    okButtonText: Ember.computed.or('okText', 'defaultOkText'),

    actions: {
      ok: function ok() {
        if (!this.get('stayOnOk')) {
          this.$('.modal').modal('hide');
        }
        this.ok();
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$('.modal').modal().on('hidden.bs.modal', function () {
        _this.close();
      });
    }
  });
});