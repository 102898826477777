define('fti-pump-selector/models/pump', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    dRatio: 1,
    sRatio: 1,

    modelNumber: (0, _attr.default)('string'),
    series: (0, _attr.default)('string'),
    diameter: (0, _attr.default)('number'),
    dashNumber: (0, _attr.default)('string'),
    discharge: (0, _attr.default)('number'),
    closedImpeller: (0, _attr.default)('boolean'),
    speed: (0, _attr.default)('number'),
    suctionLength: (0, _attr.default)('number'),
    suctionDiameter: (0, _attr.default)('number'),
    maxOperatingTempFahrenheit: (0, _attr.default)('number'),
    maxOperatingTempCelcius: (0, _attr.default)('number'),
    minFlow: (0, _attr.default)('number'),
    maxFlow: (0, _attr.default)('number'),
    maxHead: (0, _attr.default)('number'),
    maxHp: (0, _attr.default)('number'),
    bepFlow: (0, _attr.default)('number'),
    maxFlowNpsh: (0, _attr.default)('number'),
    maximumLift: (0, _attr.default)('number'),
    nsBep: (0, _attr.default)('number'),
    bFactor: (0, _attr.default)('number'),
    bestEfficiency: (0, _attr.default)('number'),
    maxSuctionLift: (0, _attr.default)('number'),
    maxSpecificGravity: (0, _attr.default)('number'),
    maxPressurePSI: (0, _attr.default)('number'),
    maxPressureBars: (0, _attr.default)('number'),
    maxImpellerDiameterCM: (0, _attr.default)('number'),
    maxImpellerDiameterIn: (0, _attr.default)('number'),
    fdaCompliantAvailable: (0, _attr.default)('boolean'),

    defaultBaseplate: (0, _attr.default)('number'),
    defaultBushingMaterial: (0, _attr.default)('number'),
    defaultConnection: (0, _attr.default)('number'),
    defaultHousingMaterial: (0, _attr.default)('number'),
    defaultHardwareMaterial: (0, _attr.default)('number'),
    defaultOringMaterial: (0, _attr.default)('number'),
    defaultSealMaterial: (0, _attr.default)('number'),
    defaultShaftMaterial: (0, _attr.default)('number'),
    defaultThrustRing: (0, _attr.default)('number'),
    defaultMagnet: (0, _attr.default)('number'),
    defaultMotorAdapter: (0, _attr.default)('number'),
    defaultFlange: (0, _attr.default)('number'),
    defaultClampRing: (0, _attr.default)('number'),

    minimumImpeller: (0, _relationships.belongsTo)('pump', { inverse: null }),
    maximumImpeller: (0, _relationships.belongsTo)('pump', { inverse: null }),

    configuration: (0, _relationships.belongsTo)('configuration'),
    pumpConstructionMaterials: (0, _relationships.hasMany)('pump-construction-material'),
    oringMaterials: (0, _relationships.hasMany)('oring-material'),
    bushingMaterials: (0, _relationships.hasMany)('bushing-material'),
    shaftMaterials: (0, _relationships.hasMany)('shaft-material'),
    housingMaterials: (0, _relationships.hasMany)('housing-material'),
    hardwareMaterials: (0, _relationships.hasMany)('hardware-material'),
    lowerBearingMaterials: (0, _relationships.hasMany)('lower-bearing-material'),
    sealMaterials: (0, _relationships.hasMany)('seal-material'),
    sealTypes: (0, _relationships.hasMany)('seal-type'),
    connections: (0, _relationships.hasMany)('connection'),
    motorSafetyFeatures: (0, _relationships.hasMany)('motor-safety-feature'),
    baseplates: (0, _relationships.hasMany)('baseplate'),
    qhns: (0, _relationships.hasMany)('qhn'),
    pumpBasePrices: (0, _relationships.hasMany)('pump-base-price'),
    pumpDownloads: (0, _relationships.hasMany)('pump-download'),
    thrustRings: (0, _relationships.hasMany)('thrust-ring'),
    magnets: (0, _relationships.hasMany)('magnet'),
    flanges: (0, _relationships.hasMany)('flange'),
    motorAdapters: (0, _relationships.hasMany)('motor-adapter'),
    clampRings: (0, _relationships.hasMany)('clamp-ring'),

    frequency: Ember.computed('@each.speed', function () {
      if (this.get('speed') > 3400) {
        return 60;
      } else if (this.get('speed') >= 2800 && this.get('speed') <= 3400) {
        return 50;
      } else if (this.get('speed') >= 1500 && this.get('speed') <= 2799) {
        return 60;
      } else if (this.get('speed') < 1500) {
        return 50;
      }
    }),

    speedRange: Ember.computed('@each.speed', function () {
      if (this.get('speed') > 1800) {
        return 'high';
      } else {
        return 'low';
      }
    }),

    head: function head(inputFlow, xArrayArg, yArrayArg) {
      if (inputFlow >= this.get('minFlow') && inputFlow <= this.get('maxFlow')) {
        var xArray = xArrayArg || this.get('qhns').mapBy('q');
        var yArray = yArrayArg || this.get('qhns').mapBy('h');
        return this.LinSpline(xArray, yArray, inputFlow);
      } else {
        return 0;
      }
    },
    efficiency: function efficiency(inputFlow, xArrayArg, yArrayArg) {
      if (inputFlow >= this.get('minFlow') && inputFlow <= this.get('maxFlow')) {
        var xArray = xArrayArg || this.get('qhns').mapBy('q');
        var yArray = yArrayArg || this.get('qhns').mapBy('eff');
        return this.LinSpline(xArray, yArray, inputFlow);
      } else {
        return 0;
      }
    },
    power: function power(inputFlow, xArrayArg, yArrayArg) {
      if (inputFlow >= this.get('minFlow') && inputFlow <= this.get('maxFlow')) {
        var xArray = xArrayArg || this.get('qhns').mapBy('q');
        var yArray = yArrayArg || this.get('qhns').mapBy('power');
        return this.LinSpline(xArray, yArray, inputFlow);
      } else {
        return 0;
      }
    },
    nol_power: function nol_power(inputFlow, xArrayArg, yArrayArg) {
      var dummy = void 0;
      var temp = 0;
      var qi = this.get('minFlow');
      var qArray = xArrayArg || this.get('qhns').mapBy('q');
      var powArray = yArrayArg || this.get('qhns').mapBy('power');

      while (qi < this.get('maxFlow')) {
        dummy = this.LinSpline(qArray, powArray, qi);
        if (dummy > temp) {
          temp = dummy;
        }
        qi = qi + 2.0;
      }

      dummy = this.LinSpline(qArray, powArray, this.get('maxFlow'));
      if (dummy > temp) {
        temp = dummy;
      }

      return temp;
    },
    net_positive_suction_head: function net_positive_suction_head(inputFlow) {
      if (inputFlow >= this.get('minFlow') && inputFlow <= this.get('maxFlow')) {
        var qArray = this.get('qhns').mapBy('q');
        var npshArray = this.get('qhns').mapBy('npsh');
        return this.LinSpline(qArray, npshArray, inputFlow);
      } else {
        return 0;
      }
    },
    LinSpline: function LinSpline(x, y, xin) {
      var x1 = 0.0;
      var x2 = 0.0;
      var y1 = 0.0;
      var y2 = 0.0;
      var slope = 0.0;
      if (xin < x[0]) {
        return y[0];
      }

      if (xin > x[x.length - 1]) {
        return y[y.length - 1];
      }

      var i = 1;
      while (i <= x.length - 1) {
        if (xin <= x[i]) {
          x1 = x[i - 1];
          x2 = x[i];
          y1 = y[i - 1];
          y2 = y[i];
          slope = (y2 - y1) / (x2 - x1);
          break;
        }
        i++;
      }

      return y1 + slope * (xin - x1);
    }
  });
});