define('fti-pump-selector/controllers/pump-selector-results/uc-part-number', ['exports', 'ember-sweetalert', 'fti-pump-selector/utils/conversions', 'fti-pump-selector/models/pump-selection'], function (exports, _emberSweetalert, _conversions, _pumpSelection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.adapterMotorSortKeys = ['overallSelectionRank:asc'];
      this.magnetSortKeys = ['ucPower:asc'];
    },


    ucPumpOptions: Ember.inject.controller(),
    pumpApplication: Ember.inject.service(),

    motorAdapter: Ember.computed.oneWay('motorAdapters.firstObject'),
    initialMotorAdapter: Ember.computed.oneWay('motorAdapters.firstObject'),
    magnet: Ember.computed.oneWay('magnetsSorted.firstObject'),

    pumpSelection: Ember.computed('model', 'partNumber', function () {
      return _pumpSelection.default.create({
        pump: this.get('model'),
        partNumber: this.get('partNumber')
      });
    }),

    partNumber: Ember.computed('modelNumber', 'magnet', 'flange', 'isFrameMounted', 'bushingMaterial', 'shaftMaterial', 'oringMaterial', 'thrustRing', 'motorAdapter', 'motor', 'pumpApplication.include_motors', function () {
      var partNumberStr = '';

      // Add model number (should always exist)
      partNumberStr += this.get('modelNumber');

      // Add Magnet Set String Part
      if (!Ember.isNone(this.get('magnet.dash_number'))) {
        partNumberStr += this.get('magnet.dash_number');
      }

      // Add Flange String Part
      if (!Ember.isNone(this.get('flange.dash_number'))) {
        partNumberStr += this.get('flange.dash_number');
      }

      // Add Coupling String Part
      if (this.get('isFrameMounted')) {
        partNumberStr += 'F';
      } else {
        partNumberStr += 'C';
      }

      // Add Bushing String Part
      if (!Ember.isNone(this.get('bushingMaterial.dash_number'))) {
        partNumberStr += this.get('bushingMaterial.dash_number');
      }

      // Add Shaft Material Part
      if (this.get('hasDriCoatShaftBushings')) {
        partNumberStr += 'D';
      } else {
        partNumberStr += 'S';
      }

      // Add O-Ring String Part
      if (!Ember.isNone(this.get('oringMaterial.dash_number'))) {
        partNumberStr += this.get('oringMaterial.dash_number');
      }

      // Add Thrust Ring String Part
      if (!Ember.isNone(this.get('thrustRing.dash_number'))) {
        partNumberStr += this.get('thrustRing.dash_number');
      }

      // Add Impeller String Part
      if (this.get('model.dashNumber') !== '') {
        partNumberStr += this.get('model.dashNumber');
      } else {
        partNumberStr += Math.floor(this.get('model.diameter') * 100);
      }

      if (this.get('isFrameMounted')) {
        // Add Motor Adapter String Part
        partNumberStr += this.get('ucPumpOptions.bearing');

        // Bronze Non-Sparking Ring String Part
        if (this.get('hasBronzeNonSparkingRing')) {
          partNumberStr += 'B';
        }
      } else {
        // Add Motor Adapter String Part
        if (!Ember.isNone(this.get('motorAdapter'))) {
          partNumberStr += this.get('motorAdapter.dash_number');
        }

        // Bronze Non-Sparking Ring String Part
        if (this.get('hasBronzeNonSparkingRing')) {
          partNumberStr += 'B';
        }

        // Add Motor String Part
        if (!Ember.isNone(this.get('motor'))) {
          partNumberStr += this.get('motor.dash_number');
        }
      }

      return partNumberStr;
    }),

    materialMaxTempsFiltered: Ember.computed('materialMaxTemps.@each.fluid', 'pumpApplication.fluid', function () {
      var _this = this;

      var mmt = this.get('materialMaxTemps');

      if (!Ember.isBlank(this.get('pumpApplication.fluid'))) {
        mmt = mmt.filter(function (n) {
          return n.get('fluid').trim() === _this.get('pumpApplication.fluid.name').trim();
        });
      }
      return mmt;
    }),

    model_id: Ember.computed.alias('model'),
    modelNumber: Ember.computed.alias('model.modelNumber'),

    magnets: Ember.computed('model.{magnets,pumpCalculations.power,speed}', function () {
      var _this2 = this;

      var magnets = this.get('model.magnets');
      var compareTemp = new _conversions.Temperature(this.get('pumpApplication.temperature_unit'), this.get('pumpApplication.temperature')).convertTo('F');
      var applicationTorque = void 0;
      if (this.get('pumpApplication.motor_size.value') === 'non_overloading') {
        applicationTorque = this.get('model.pumpCalculations.nol_power') * 63025 / this.get('model.speed');
      } else {
        applicationTorque = this.get('model.pumpCalculations.power') * 63025 / this.get('model.speed');
      }

      magnets = magnets.filter(function (m) {
        var compatibleTemp = m.get('maxTemp') >= compareTemp;
        var compatibleTorque = m.get('maxTorque') >= applicationTorque;
        if (compatibleTemp && compatibleTorque) {
          var maxMagnetHP = m.get('maxTorque') * _this2.get('model.speed') / 63025;
          m.set('ucPower', maxMagnetHP);
          return m;
        }
      });

      return magnets;
    }),
    magnetsSorted: Ember.computed.sort('magnets', 'magnetSortKeys'),

    flanges: Ember.computed.alias('model.flanges'),
    flange: Ember.computed('_flange', 'flanges.[]', 'defaultFlangeFound.id', {
      get: function get() {
        // If the internal property has a value, return it
        if (!Ember.isNone(this.get('_flange'))) {
          return this.get('_flange');
        }

        var defaultExistsInMaterialsList = this.get('flanges').findBy('id', this.get('defaultFlangeFound.id'));
        if (defaultExistsInMaterialsList) {
          return this.get('defaultFlangeFound');
        } else {
          return this.get('flanges.firstObject');
        }
      },
      set: function set(key, value) {
        this.detectFactoryWarning(value);
        this.set('_flange', value);
        return this.get('_flange');
      }
    }),
    defaultFlangeFound: Ember.computed('flanges.[]', 'model.defaultFlange', function () {
      var flanges = this.get('flanges');
      var defaultFlange = this.get('model.defaultFlange');
      var defaultOptionFound = flanges.findBy('id', defaultFlange.toString());

      if (defaultOptionFound) {
        return defaultOptionFound;
      }
    }),

    bushingMaterials: Ember.computed('model.bushingMaterials.@each.name', 'pumpApplication.{temperature_unit,temperature}', 'materialMaxTempsFiltered.[]', 'hasDriCoatShaftBushings', function () {
      var _this3 = this;

      var bushingMaterials = this.get('model.bushingMaterials');

      if (this.get('hasDriCoatShaftBushings')) {
        bushingMaterials = bushingMaterials.filter(function (m) {
          if (m.get('dash_number') === 'D') {
            return m;
          }
        });
      } else {
        var compareTemp = new _conversions.Temperature(this.get('pumpApplication.temperature_unit'), this.get('pumpApplication.temperature')).convertTo('F');

        bushingMaterials = bushingMaterials.filter(function (m) {
          var fluidMaxTemp = _this3.get('materialMaxTempsFiltered').findBy('material', m.get('name'));
          var compatibleTemp = !Ember.isBlank(fluidMaxTemp) && compareTemp < fluidMaxTemp.get('maxTemp');

          if (compatibleTemp) {
            return m;
          }
        });
      }

      return bushingMaterials;
    }),
    bushingMaterial: Ember.computed('_bushingMaterial', 'defaultBushingMaterialFound', 'bushingMaterials.[]', {
      get: function get() {
        // If the internal property has a value, return it
        if (!Ember.isNone(this.get('_bushingMaterial'))) {
          return this.get('_bushingMaterial');
        }

        var defaultExistsInMaterialsList = this.get('bushingMaterials').findBy('id', this.get('defaultBushingMaterialFound.id'));
        if (defaultExistsInMaterialsList) {
          return this.get('defaultBushingMaterialFound');
        } else {
          return this.get('bushingMaterials.firstObject');
        }
      },
      set: function set(key, value) {
        this.detectFactoryWarning(value);
        this.set('_bushingMaterial', value);
        return this.get('_bushingMaterial');
      }
    }),
    defaultBushingMaterialFound: Ember.computed('bushingMaterials', 'model.defaultBushingMaterial', function () {
      var bushingMaterials = this.get('bushingMaterials');
      var defaultBushingMaterial = this.get('model.defaultBushingMaterial');
      var defaultOptionFound = bushingMaterials.findBy('id', defaultBushingMaterial.toString());

      if (defaultOptionFound) {
        return defaultOptionFound;
      }
    }),

    oringMaterials: Ember.computed('model.oringMaterials.@each.name', 'pumpApplication.{temperature_unit,temperature}', 'materialMaxTempsFiltered.[]', function () {
      var _this4 = this;

      var oringMaterials = this.get('model.oringMaterials');
      var compareTemp = new _conversions.Temperature(this.get('pumpApplication.temperature_unit'), this.get('pumpApplication.temperature')).convertTo('F');

      oringMaterials = oringMaterials.filter(function (m) {
        var fluidMaxTemp = _this4.get('materialMaxTempsFiltered').findBy('material', m.get('name'));
        var compatibleTemp = !Ember.isBlank(fluidMaxTemp) && compareTemp < fluidMaxTemp.get('maxTemp');

        if (compatibleTemp) {
          return m;
        }
      });

      return oringMaterials;
    }),
    oringMaterial: Ember.computed('_oringMaterial', 'defaultOringMaterialFound', 'oringMaterials.[]', {
      get: function get() {
        // If the internal property has a value, return it
        if (!Ember.isNone(this.get('_oringMaterial'))) {
          return this.get('_oringMaterial');
        }

        var defaultExistsInMaterialsList = this.get('oringMaterials').findBy('id', this.get('defaultOringMaterialFound.id'));
        if (defaultExistsInMaterialsList) {
          return this.get('defaultOringMaterialFound');
        } else {
          return this.get('oringMaterials.firstObject');
        }
      },
      set: function set(key, value) {
        this.detectFactoryWarning(value);
        this.set('_oringMaterial', value);
        return this.get('_oringMaterial');
      }
    }),
    defaultOringMaterialFound: Ember.computed('oringMaterials', 'model.defaultOringMaterial', function () {
      var oringMaterials = this.get('oringMaterials');
      var defaultOringMaterial = this.get('model.defaultOringMaterial');
      var defaultOptionFound = oringMaterials.findBy('id', defaultOringMaterial.toString());

      if (defaultOptionFound) {
        return defaultOptionFound;
      }
    }),

    shaftMaterials: Ember.computed('model.shaftMaterials.@each.name', 'temperature_unit', 'temperature', 'materialMaxTempsFiltered.[]', function () {
      var _this5 = this;

      var shaftMaterials = this.get('model.shaftMaterials');
      var compareTemp = new _conversions.Temperature(this.get('temperature_unit'), this.get('temperature')).convertTo('F');

      shaftMaterials = shaftMaterials.filter(function (m) {
        var fluidMaxTemp = _this5.get('materialMaxTempsFiltered').findBy('material', m.get('name'));
        var compatibleTemp = !Ember.isBlank(fluidMaxTemp) && compareTemp < fluidMaxTemp.get('maxTemp');

        if (compatibleTemp) {
          return m;
        }
      });

      return shaftMaterials;
    }),
    shaftMaterial: Ember.computed('_shaftMaterial', 'defaultShaftMaterialFound', 'shaftMaterials.[]', {
      get: function get() {
        // If the internal property has a value, return it
        if (!Ember.isNone(this.get('_shaftMaterial'))) {
          return this.get('_shaftMaterial');
        }

        var defaultExistsInMaterialsList = this.get('shaftMaterials').findBy('id', this.get('defaultShaftMaterialFound.id'));
        if (defaultExistsInMaterialsList) {
          return this.get('defaultShaftMaterialFound');
        } else {
          return this.get('shaftMaterials.firstObject');
        }
      },
      set: function set(key, value) {
        this.detectFactoryWarning(value);
        this.set('_shaftMaterial', value);
        return this.get('_shaftMaterial');
      }
    }),
    defaultShaftMaterialFound: Ember.computed('shaftMaterials', 'model.defaultShaftMaterial', function () {
      var shaftMaterials = this.get('shaftMaterials');
      var defaultShaftMaterial = this.get('model.defaultShaftMaterial');
      var defaultOptionFound = shaftMaterials.findBy('id', defaultShaftMaterial.toString());

      if (defaultOptionFound) {
        return defaultOptionFound;
      }
    }),

    thrustRings: Ember.computed('model.thrustRings.[]', 'pumpApplication.{temperature_unit,temperature}', 'materialMaxTempsFiltered.[]', function () {
      var thrustRings = this.get('model.thrustRings');
      // let compareTemp = new Temperature(this.get('pumpApplication.temperature_unit'), this.get('pumpApplication.temperature')).convertTo('F');

      // thrustRings = thrustRings.filter (m) =>
      //   fluidMaxTemp = @get('materialMaxTempsFiltered').findBy('material', m.get('name'))
      //   // Test to make sure that material is able to handle temperature of material
      //   compatibleTemp = !isBlank(fluidMaxTemp) and compareTemp < fluidMaxTemp.get('maxTemp')
      //   // Test to remove defaults from available options
      //   isDefaultOption = m.get('id') == @get('model.defaultThrustRing').toString()
      //
      //   return m if compatibleTemp and not isDefaultOption

      return thrustRings;
    }),
    thrustRing: Ember.computed('_thrustRing', 'defaultThrustRingFound', 'thrustRings.[]', {
      get: function get() {
        // If the internal property has a value, return it
        if (!Ember.isNone(this.get('_thrustRing'))) {
          return this.get('_thrustRing');
        }

        var defaultExistsInMaterialsList = this.get('thrustRings').findBy('id', this.get('defaultThrustRingFound.id'));
        if (defaultExistsInMaterialsList) {
          return this.get('defaultThrustRingFound');
        } else {
          return this.get('thrustRings.firstObject');
        }
      },
      set: function set(key, value) {
        this.detectFactoryWarning(value);
        this.set('_thrustRing', value);
        return this.get('_thrustRing');
      }
    }),
    defaultThrustRingFound: Ember.computed('thrustRings', 'model.defaultThrustRing', function () {
      var thrustRings = this.get('thrustRings');
      var defaultThrustRing = this.get('model.defaultThrustRing');
      var defaultOptionFound = thrustRings.findBy('id', defaultThrustRing.toString());

      if (defaultOptionFound) {
        return defaultOptionFound;
      }
    }),

    motorAdapters: Ember.computed('model.motorAdapters.[]', 'pumpApplication.motor_adapter', function () {
      var _this6 = this;

      var _motorAdapters = this.get('model.motorAdapters');
      var filteredAdapters = _motorAdapters.filter(function (adapter) {
        return adapter.get('adapterType') === _this6.get('pumpApplication.motor_adapter.value').toUpperCase();
      });
      return filteredAdapters;
    }),

    availableMotors: Ember.computed('motorAdapter.compatibleMotors.[]', function () {
      var _motors = this.get('motorAdapter.compatibleMotors').filter(function (motor) {
        return motor.get('constructor.modelName') === 'uc-motor';
      });
      return _motors;
    }),

    removeMotorAfterMAChange: Ember.observer('motorAdapter', function () {
      var _this7 = this;

      if (this.get('motorAdapter') && !Ember.isNone(this.get('motor'))) {
        var foundMotor = this.get('motorAdapter.compatibleMotors').find(function (item) {
          return item.get('id') === _this7.get('motor.id');
        });

        if (Ember.isNone(foundMotor)) {
          return this.set('motor', null);
        }
      }
    }),

    adapterMotorResults: Ember.computed('motorAdapters.[]', function () {
      var _this8 = this;

      var _motorList = [];

      this.get('motorAdapters').forEach(function (adapter) {
        var motors = adapter.get('compatibleMotors').filter(function (motor) {
          var motorAllowed = motor.get('constructor.modelName') === 'uc-motor';
          var frequency = _this8.get('pumpApplication.frequency.value');

          // Use non-overloading power calculation if selected as motor / magnet size
          // Otherwise use normal power as a comparison.
          if (_this8.get('pumpApplication.motor_size.value') === 'non_overloading') {
            motorAllowed = motorAllowed && motor.get('power') >= _this8.get('model.pumpCalculations.nol_power');
          } else {
            motorAllowed = motorAllowed && motor.get('power') >= _this8.get('model.pumpCalculations.power');
          }
          motorAllowed = motorAllowed && motor.get('motorTypeCode') === _this8.get('pumpApplication.motor_type.value');
          motorAllowed = motorAllowed && motor.get('phase') === _this8.get('pumpApplication.motor_phase.value');

          motorAllowed = motorAllowed && (motor.get('is' + frequency + 'Hertz') === true || motor.get('isDualHertz') === true);
          motorAllowed = motorAllowed && (motor.get('speedRange') === _this8.get('pumpApplication.motor_speed.value') || _this8.get('pumpApplication.motor_speed.value') === 'both');

          return motorAllowed;
        });

        motors.forEach(function (motor) {
          var adapterSelectionRank = adapter.get('selectionRank') !== undefined ? adapter.get('selectionRank') : 0;
          var motorSortOrder = motor.get('sortOrder') !== undefined ? motor.get('sortOrder') : 0;
          var overallSR = adapterSelectionRank + motorSortOrder;

          var resultLine = Ember.Object.create({
            id: adapter.get('id') + ':' + motor.get('id'),
            adapter: adapter,
            motor: motor,
            overallSelectionRank: overallSR
          });

          resultLine.selected = resultLine === _this8.get('selectedResult');

          _motorList.push(resultLine);
        });
      });

      return _motorList;
    }),

    sortedAdapterMotorResults: Ember.computed.sort('adapterMotorResults', 'adapterMotorSortKeys'),
    bestFitAdapterMotorResult: Ember.computed('sortedAdapterMotorResults.[]', function () {
      return this.get('sortedAdapterMotorResults.firstObject');
    }),

    includeMotors: Ember.computed.alias('pumpApplication.include_motors'),
    isFrameMounted: Ember.computed.alias('ucPumpOptions.isFrameMounted'),
    hasDriCoatShaftBushings: Ember.computed.alias('ucPumpOptions.hasDriCoatShaftBushings'),
    hasBronzeNonSparkingRing: Ember.computed.alias('ucPumpOptions.hasBronzeNonSparkingRing'),

    validMotorForMagnet: Ember.computed('magnet.ucPower', 'motor.power', function () {
      if (this.get('pumpApplication.include_motors')) {
        return Math.round(this.get('magnet.ucPower')) >= this.get('motor.power');
      } else {
        return true;
      }
    }),

    formValid: Ember.computed('validMotorForMagnet', function () {
      var valid = true;
      valid = valid && this.get('validMotorForMagnet');
      return valid;
    }),

    actions: {
      // Selection action for ember-select on [magnet]
      selectMagnet: function selectMagnet(value, option) {
        this.set('magnet', option);
      },

      // Selection action for ember-select on [flange]
      selectFlange: function selectFlange(value, option) {
        this.set('flange', option);
      },

      // Selection action for ember-select on [bushingMaterial]
      selectBushingMaterial: function selectBushingMaterial(value, option) {
        this.set('bushingMaterial', option);
      },

      // Selection action for ember-select on [oringMaterial]
      selectOringMaterial: function selectOringMaterial(value, option) {
        this.set('oringMaterial', option);
      },

      // Selection action for ember-select on [thrustRing]
      selectThrustRing: function selectThrustRing(value, option) {
        this.set('thrustRing', option);
      },

      // Selection action for ember-select on [motorAdapter]
      selectMotorAdapter: function selectMotorAdapter(value, option) {
        this.set('motorAdapter', option);
      },
      toggleAdapterMotorModal: function toggleAdapterMotorModal() {
        this.toggleProperty('isShowingAMModal');
      },
      handleModalSelectedResult: function handleModalSelectedResult(selectedResult) {
        this.set('modalSelectedResult', selectedResult);
      },
      handleAdapterMotorSelection: function handleAdapterMotorSelection() {
        if (this.get('modalSelectedResult')) {
          if (Math.trunc(this.get('magnet.ucPower')) < this.get('modalSelectedResult.motor.power')) {
            (0, _emberSweetalert.default)({
              title: 'Over Magnet Capacity',
              text: 'The motor selected overpowers the current magnet. Please select an appropriate size magnet for this motor.',
              type: 'warning',
              confirmButtonText: 'Try again'
            }).catch(_emberSweetalert.default.noop);
          }

          this.set('motorAdapter', this.get('modalSelectedResult.adapter'));
          this.set('motor', this.get('modalSelectedResult.motor'));
          this.toggleProperty('isShowingAMModal');
        }
      }
    }
  });
});